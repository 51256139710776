/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './Tall.module.css';
import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const TallBanner = ({ data, salesforceResponse }) =>     {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Standard/Tall/Tall.js',
            message: 'No data available to render component',
        });
    }
    useStyles(styles);
    const messageTextColor = (theme) => (theme === 'onDark' ? '#ffffff' : '#2f2f2f');
    const bannerLeft = {
        background_image: data?.banner_left.background_image,
        message: {
            ...data?.banner_left?.message,
            color: messageTextColor(data?.banner_left?.message?.message_theme),
            cta: {
                ...data?.banner_left?.message?.cta,
                presentation: 'secondary',
                theme: data?.banner_left?.message?.message_theme,
            },
        },
    };
    const bannerRight = {
        background_image: data?.banner_right.background_image,
        message: {
            ...data?.banner_right?.message,
            color: messageTextColor(data?.banner_right?.message?.message_theme),
            cta: {
                ...data?.banner_right?.message?.cta,
                presentation: 'secondary',
                theme: data?.banner_right?.message?.message_theme,
            },
        },
    };
    const backgroundLeft = {
        desktop: bannerLeft.background_image?.desktop?.url,
        tablet: bannerLeft.background_image?.tablet?.url, // can add fallbacks easily this way
        mobile: bannerLeft.background_image?.mobile?.url,
    };
    const backgroundRight = {
        desktop: bannerRight.background_image?.desktop?.url,
        tablet: bannerRight.background_image?.tablet?.url, // can add fallbacks easily this way
        mobile: bannerRight.background_image?.mobile?.url,
    };
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    let backgroundHeight = {};
    let bannerSizeClass = styles.tilesBanner;
    switch (data?.size) {
        case 'short':
            backgroundHeight = '200px';
            bannerSizeClass = styles.shortBanner;
            break;
        case 'medium':
            backgroundHeight = '300px';
            bannerSizeClass = styles.mediumBanner;
            break;
        default: backgroundHeight = '400px';
    }
    const imageQuality = '?auto=webp';
    const handleClick = universalHandleClick(salesforceResponse);
    const leftBannerNotClickable = !bannerLeft.message.cta.link.href && !bannerLeft?.message?.cta?.link?.title;
    const rightBannerNoClickable = !bannerRight.message.cta.link.href && !bannerRight?.message?.cta?.link?.title;
    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgroundLeft[device(matches)]}${imageQuality})`, height: backgroundHeight }} className={`${styles.banner} ${styles.leftBanner} ${leftBannerNotClickable ? styles.disableClick : ''} ${bannerSizeClass}`} role="presentation" onClick={() => handleClick(bannerLeft.message.cta)}>
                {(bannerLeft?.message?.heading || bannerLeft?.message?.sub_heading) && <MessageStandard data={bannerLeft.message} fullBackground size={data?.size} />}
            </div>
            <div style={{ backgroundImage: `url(${backgroundRight[device(matches)]}${imageQuality})`, height: backgroundHeight }} className={`${styles.banner} ${styles.rightBanner} ${rightBannerNoClickable ? styles.disableClick : ''} ${bannerSizeClass}`} role="presentation" onClick={() => handleClick(bannerRight.message.cta)}>
                {(bannerRight?.message?.heading || bannerRight?.message?.sub_heading) &&  <MessageStandard data={bannerRight.message} fullBackground size={data?.size} />}
            </div>
            <img src={`${backgroundRight[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" />
        </>
    );
    return (
        <div data-testid="tallBanner" className={styles.bannerContainer}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};
TallBanner.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
TallBanner.defaultProps = {
    salesforceResponse: {},
};
export default TallBanner;
